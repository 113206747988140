@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* ------ Add your CSS below this line (or @import your other CSS files). ------- */

#planningMenuCheckbox:checked ~ #planningMenu{
	@apply ml-0 right-0;
}
#planningMenuCheckbox:checked ~ #planningMenu > label{
	@apply rotate-45 -left-6;
}